* {
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  transition: all ease-in-out 0.5s;
  z-index: 9997;
  transition: all 0.5s;
  padding: 0 15px;
  background: #040b14;
  overflow-y: auto;
  padding-top: 40px;
}

.header .profile img {
  margin: 15px auto;
  display: block;
  width: 120px;
  border: 8px solid #555555;
}

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
.header .profile h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  -moz-text-align-last: center;
  text-align-last: center;
  font-family: "Great Vibes", cursive;
}

.header .profile h1 a,
.header .profile h1 a:hover {
  color: #fff;
  text-decoration: none;
}
.profile {
  padding-bottom: 30px;
}
.header .profile .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.header .profile .social-links a:hover {
  background: #149ddd;
  color: #fff;
  text-decoration: none;
}

.main {
  margin-right: 300px;
}

@media (max-width: 1199px) {
  .header {
    right: -300px;
  }
  .main {
    margin-right: 0;
  }
}
.example {
  position: absolute !important;
  top: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap");
.po {
  color: white;
  font-family: "Architects Daughter", cursive;
  font-size: 40px;
  -moz-text-align-last: center;
  text-align-last: center;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
  padding: 30px 0 0 0;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
}

.nav-menu a {
  display: flex;
  text-align: center;
  color: #a8a9b4;
  padding: 22px 5px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 20px;
  cursor: pointer;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  letter-spacing: 2px;
}

.nav-menu a i {
  font-size: 34px;
  padding-right: 8px;
  color: #6f7180;
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu li:hover > a {
  text-decoration: none;
  color: #fff;
}

.nav-menu a:hover i,
.nav-menu .active i,
.nav-menu li:hover > a i {
  color: #149ddd;
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  background-color: #149ddd;
  color: #fff;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border-radius: 50px;
  cursor: pointer;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active #header {
  left: 0;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background: #f5f8fd;
}

.section-title {
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #173b6c;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  background: #149ddd;
  bottom: 0;
  left: 0;
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");
.foo {
  font-size: 20px;
  font-family: "Merienda", cursive;
}

.content {
  width: 500px;
}
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #149ddd;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.iii {
  height: 350px;
  margin-left: 30px;
  border-radius: 55px;
}

.sss {
  margin-top: 20px;
  text-align: center;
}

.sss a {
  font-size: 22px;
  display: inline-block;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 30px;
  border-radius: 50%;

  width: 46px;
  height: 36px;
  transition: 0.3s;
}

.sss a:hover {
  background: #149ddd;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Resume
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Akills
--------------------------------------------------------------*/

.ski {
  font-size: 16px;
  background: #e4edf9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 20px;
  margin-right: 20px;
}

.resume .resume-title {
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  color: #050d18;
}

.resume .resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #1f5297;
  position: relative;
}

.resume .resume-item h4 {
  line-height: 28px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #050d18;
  margin-bottom: 10px;
}

.resume .resume-item h5 {
  font-size: 16px;
  background: #e4edf9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #1f5297;
}

/* General button style (reset) */
.btnn {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 20px;
  display: inline-block;
  margin: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
}

.btnn:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

/* Pseudo elements for icons */
.btnn:before {
  font-family: "icomoon";

  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

/* Button 2 */
.btn-2 {
  background: #000000;
  color: #fff;
  box-shadow: 0 10px #444444;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

/* Button 2h */
.btn-2h {
  border-radius: 20px;
}

.btn-2h:hover {
  box-shadow: 0 4px #444444;
  color: #ffffff;
  top: 4px;
  text-decoration: none;
}

.btn-2h:active {
  box-shadow: 0 0 #444444;
  top: 6px;
}

.bbb {
  position: relative;
  text-align: center;
  top: 55px;
}

@media (max-width: 990px) {
  .btnn {
    padding: 15px;
  }
  .bbb {
    top: -30px;
  }
}

.temppp {
  padding-top: 75px;
}
.hhhh {
  border: none;
  border-top: 2px dashed rgb(32, 32, 32);
  color: #fff;
  background-color: #fff;
  height: 2px;
}

@media (max-width: 820px) {
  .temppp {
    padding-top: 0px;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  background: #fff;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #272829;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #149ddd;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: -60px;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  font-size: 28px;
  text-align: center;
  background: rgba(20, 157, 221, 0.75);
  transition: 0.3s;
  width: 50%;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  background: rgba(20, 157, 221, 0.95);
}

.grid__item {
  background-color: none;
  border: none;
  border-radius: 15px;
  height: auto;
  overflow: hidden;
  /* box-shadow: 0 3rem 6rem rgba(0, 0, 0, .1); */
  cursor: pointer;
  transition: 0.2s;
}
.grid__item:hover {
  transform: translateY(-0.5%);
  box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
}
.card__img {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.card__content {
  padding: 15px;
}
.card__header {
  font-size: 25px;
  font-weight: 500;
  color: #0d0d0d;
  margin-bottom: 1.5rem;
}
.card__text {
  font-size: 15px;
  letter-spacing: 0.05rem;
  line-height: 1.7;
  color: #3d3d3d;
  margin-bottom: 0px;
}

.prof {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__btn {
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  color: #3363ff;
  background-color: #dff3fc;
  border: none;
  border-radius: 0.4rem;
  transition: 0.2s;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}
.card__btn span {
  margin-left: 1rem;
  transition: 0.2s;
}
.card__btn:hover,
.card__btn:active {
  background-color: #dce4ff;
}
.card__btn:hover span,
.card__btn:active span {
  margin-left: 1.5rem;
}
.navl {
  text-decoration: none;
}

.grid {
  display: grid;
  grid-row-gap: 35px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
}

@media (max-width: 1400px) {
  .grid {
    width: auto;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    width: auto;
  }
}
@media (max-width: 700px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    width: auto;
  }
}
@media (max-width: 375px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    width: auto;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact .info {
  padding: 30px;
  background: none;
  width: 100%;
}

.contact .info i {
  font-size: 20px;
  color: #149ddd;
  float: left;
  width: 44px;
  height: 44px;
  background: #dff3fc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #050d18;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #173b6c;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #149ddd;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #030303;
  color: #fff;
}

.contact .formm {
  width: 100%;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  border-radius: 25px;
}

.contact .formm .form-group {
  padding-bottom: 8px;
}

.contact .formm .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .formm .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .formm .error-message br + br {
  margin-top: 25px;
}

.contact .formm .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .formm .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .formm .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
  align-content: center;
}

.contact .formm .form-group {
  margin-bottom: 15px;
}

.contact .formm label {
  padding-bottom: 8px;
}

.contact .formm input,
.contact .formm textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .formm input {
  height: 44px;
}

.contact .formm textarea {
  padding: 10px 15px;
}

.contact .formm button[type="submit"] {
  background: #149ddd;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .formm button[type="submit"]:hover {
  background: #212122;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.iic {
  height: 20px;
  size: 20px;
}
.sss2 a {
  font-size: 22px;
  display: inline-block;
  background: #ffffff;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 30px;
  border-radius: 50%;

  width: 46px;
  height: 36px;
  transition: 0.3s;
}

.sss2 a:hover {
  background: #000000;
  color: rgb(158, 72, 72);
  text-decoration: none;
}
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
.namee {
  /* font-family: "Architects Daughter", cursive; */
  font-family: 'PT Serif', serif;
  font-size: 21px;
  color: #374151; 

  font-weight: bold;
}

.form-control {
  border-radius: 20px;
}

.tem {
  text-align: center;
}

.tem img {
  height: 250px;
}

.inl {
  display: flex;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: url("./img/wall.jpeg") top center;
  background-size: cover;
}

#hero:before {
  content: "";
  background: rgba(5, 13, 24, 0.3);
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

#hero .hero-container {
  position: relative;
  z-index: 10;
  width: 600px;
  left: 150px;
  text-shadow: 0px 0px 0 rgb(0, -62, 0), 1px 1px 0 rgb(-255, -317, 0),
    2px 2px 1px rgba(0, 0, 0, 0.81), 2px 2px 1px rgba(0, 0, 0, 0.5),
    0px 0px 1px rgba(0, 0, 0, 0.2);
}

#hero h1 {
  margin: 0 0 10px 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

#hero h2 {
  color: #fff;
  margin-bottom: 50px;
  margin-top: 10px;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  font-family: monospace;
}

#hero h2 span {
  color: #fff;
  padding-bottom: 4px;
  letter-spacing: 1px;
  border-bottom: 3px solid #149ddd;
  font-family: monospace;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 35px;
    line-height: 36px;
  }

  #hero h2 {
    font-size: 24px;
  }
  #hero .hero-container {
    left: 50px;
  }
}

@media (max-width: 450px) {
  #hero .hero-container {
    left: 10px;
  }
  .iii {
    margin-left: 20px;
  }
}

@media (max-width: 1400px) {
  .iii {
    margin-left: 0px;
  }
}


.proj{
  display: flex; 
justify-content: center; 
align-items: center; 
height: 100vh; 
}

.proj2{
  overflow: hidden; 
max-width: 25rem; 
border-radius: 0.25rem; 
box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
}

.proj3{
  width: 100%; 
}

.proj4{
  padding-top: 1rem;
padding-left: 1.5rem;
padding-right: 1.5rem; 
}
.proj44{
  padding-top: 1rem;
padding-bottom: 1rem; 
padding-left: 1.5rem;
padding-right: 1.5rem; 
}

.proj5{
  margin-bottom: 0.5rem; 
font-size: 1.25rem;
line-height: 1.75rem; 
font-weight: 700; 
}

.proj6{
  color: #374151; 
font-size: 1rem;
line-height: 1.5rem; 

margin-bottom:7px !important;
}

.proj7{
  display: inline-block; 
padding-top: 0.25rem;
padding-bottom: 0.25rem; 
padding-left: 0.75rem;
padding-right: 0.75rem; 
margin-right: 0.5rem; 
background-color: #E5E7EB; 
color: #374151; 
font-size: 0.875rem;
line-height: 1.25rem; 
font-weight: 600; 
border-radius: 9999px; 
margin-bottom:5px;
}



.grid2 {
  display: grid;
  grid-row-gap: 35px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
}

@media (max-width: 1400px) {
  .grid2 {
    width: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 900px) {
  .grid2 {
    grid-template-columns: repeat(1, 1fr);
    width: auto;
  }
}
@media (max-width: 700px) {
  .grid2 {
    grid-template-columns: repeat(1, 1fr);
    width: auto;
  }
}
@media (max-width: 375px) {
  .grid2 {
    grid-template-columns: repeat(1, 1fr);
    width: auto;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
.heeeeee{
  font-style: uppercase;
  text-align: center;
  color: #173b6c;
  font-family: 'Fjalla One', sans-serif;
  padding-bottom: 25px;
}